/* ----------------------------------------------
 * Generated by Animista on 2021-6-5 0:19:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-in-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-vertical {
	0% {
		-webkit-transform: translateZ(-800px) rotateY(90deg);
		transform: translateZ(-800px) rotateY(90deg);
		opacity: 0;
	}
	54% {
		-webkit-transform: translateZ(-160px) rotateY(87deg);
		transform: translateZ(-160px) rotateY(87deg);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateZ(0) rotateY(0);
		transform: translateZ(0) rotateY(0);
	}
}
@keyframes slit-in-vertical {
	0% {
		-webkit-transform: translateZ(-800px) rotateY(90deg);
		transform: translateZ(-800px) rotateY(90deg);
		opacity: 0;
	}
	54% {
		-webkit-transform: translateZ(-160px) rotateY(87deg);
		transform: translateZ(-160px) rotateY(87deg);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateZ(0) rotateY(0);
		transform: translateZ(0) rotateY(0);
	}
}

.slit-in-vertical {
	-webkit-animation: slit-in-vertical 1s linear both;
	animation: slit-in-vertical 1s linear both;
}
