/* ----------------------------------------------
 * Generated by Animista on 2021-6-5 0:1:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes rotate-diagonal-1 {
	0% {
		transform: rotate3d(1, 1, 0, 0deg);
	}
	50% {
		transform: rotate3d(1, 1, 0, -180deg);
	}
	100% {
		transform: rotate3d(1, 1, 0, -360deg);
	}
}

.rotate-diagonal-1:hover {
	animation: rotate-diagonal-1 1s linear both;
}
